<template>
  <div>
    <b-modal
      size="lg"
      :visible="isShowVoucherModal"
      :title="form.id ? form.name : 'Thêm nhóm voucher'"
      centered
      cancel-variant="outline-secondary"
      :hide-footer="true"
      @hide="hideMethod"
      @change="(val) => $emit('update:is-show-voucher-modal', val)"
    >
      <b-overlay
        :show="loading"
        rounded="sm"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
      >
        <validation-observer ref="addVoucherValidation">
          <!-- form -->
          <b-form>
            <b-row v-if="!form.id">
              <b-col cols="6">
                <b-form-group
                  :label="$t('lbl_voucher_group_name')"
                  class="mb-2 required"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('lbl_voucher_group_name')"
                    rules="required"
                  >
                    <b-form-input
                      placeholder="VD: Nhóm Voucher cho khách hàng mới"
                      v-model="form.name"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group
                  :label="$t('lbl_voucher_group_value')"
                  class="mb-2 required"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('lbl_voucher_group_value')"
                    rules="required"
                  >
                    <div class="flex items-center gap-2">
                      <b-form-input
                        v-model.number="form.voucher_value"
                        type="number"
                        min="0"
                        :max="form.voucher_type === 2 ? 100 : null"
                        @input="handleVoucherValue"
                      />
                      <div class="flex items-center">
                        <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          :variant="
                            form.voucher_type === 1
                              ? 'primary'
                              : 'outline-primary'
                          "
                          style="
                            border-bottom-right-radius: 0;
                            border-top-right-radius: 0;
                          "
                          @click="() => handleType(1)"
                          >VNĐ</b-button
                        >
                        <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          :variant="
                            form.voucher_type === 2
                              ? 'primary'
                              : 'outline-primary'
                          "
                          style="
                            border-bottom-left-radius: 0;
                            border-top-left-radius: 0;
                          "
                          @click="() => handleType(2)"
                          >%</b-button
                        >
                      </div>
                    </div>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col v-if="form.voucher_type === 2" cols="4">
                <b-form-group
                  :label="$t('lbl_voucher_group_max_voucher_value')"
                  class="mb-2 required"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('lbl_voucher_group_max_voucher_value')"
                    rules="required"
                  >
                    <div class="flex items-center gap-2">
                      <b-form-input
                        v-model.number="form.max_voucher_value"
                        type="number"
                        min="0"
                      />
                    </div>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col cols="4">
                <b-form-group
                  :label="$t('lbl_voucher_prefix')"
                  class="mb-2 required"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('lbl_voucher_prefix')"
                    rules="required"
                  >
                    <b-form-input
                      placeholder="VD: HODO"
                      v-model="form.prefix"
                      
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col cols="4">
                <b-form-group
                  :label="$t('lbl_number_of_code')"
                  class="mb-2 required"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('lbl_number_of_code')"
                    rules="required"
                  >
                    <b-form-input
                      placeholder="VD: 10"
                      v-model.number="form.number_of_code"
                      type="number"
                      min="0"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col cols="4">
                <b-form-group
                  :label="$t('lbl_voucher_code_length')"
                  class="mb-2 required"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('lbl_voucher_code_length')"
                    rules="required"
                  >
                    <b-form-input
                      placeholder="VD: 10"
                      v-model.number="form.voucher_len"
                      type="number"
                      min="0"
                      max="10"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
        <div v-if="form.id">
          <b-button
            class="mt-0 px-0 pt-0"
            variant="link"
          >
            {{ "Danh sách mã vouchers" }}
          </b-button>
          <b-collapse v-model="isTableVisible">
            <TableVouchers :voucher_group_id="form.id" />
          </b-collapse>
        </div>

        <div v-if="!form.id" class="d-flex mt-2">
          <div class="ml-auto">
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              class="mr-2"
              @click="$emit('update:is-show-voucher-modal', false)"
              >Hủy</b-button
            >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              :loading="loading"
              @click="handleSubmit"
              >{{ form.id ? "Cập nhật" : "Thêm" }}</b-button
            >
          </div>
        </div>
      </b-overlay>
    </b-modal>
  </div>
</template>
<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BModal,
  VBModal,
  BFormGroup,
  BFormInput,
  BForm,
  BRow,
  BCol,
  BButton,
  BOverlay,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { required } from "@validations";
import Vue from "vue";
import i18n from "@/libs/i18n";
import useActionsCampaigns from "../../views/Campaigns/_id/useActionsCampaigns";
import { isEmpty } from "lodash";
import TableVouchers from "@/components/Campaign/TableVouchers.vue";

const defaultForm = {
  voucher_type: 1,
};
export default {
  components: {
    BModal,
    BButton,
    BOverlay,
    BCol,
    BRow,
    BFormGroup,
    BFormInput,
    BForm,
    ValidationProvider,
    ValidationObserver,
    TableVouchers,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  props: {
    isShowVoucherModal: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      default: () => {},
    },
  },
  setup() {
    const mixData = useActionsCampaigns();

    return { ...mixData };
  },
  data() {
    return {
      form: defaultForm,
      voucher_percent: 0,
      voucher_amount: 0,
      required,
      loading: false,
      selectedProducts: [],
      isTableVisible: true, // Data property to control the collapse state
    };
  },
  watch: {
    // Watch for changes to the `data` prop
    data: {
      immediate: true, // Trigger this watcher when component is mounted
      handler(newValue) {
        this.form = isEmpty(newValue) ? defaultForm : { ...newValue };
      },
    },
  },
  methods: {
    hideMethod() {
      this.$emit("closeModalVoucher");
    },
    handleVoucherValue() {
      if (this.form.voucher_type === 1) {
        this.voucher_amount = this.form.voucher_value;
      } else {
        this.voucher_percent = this.form.voucher_value;
      }
    },
    handleType(type) {
      this.form.voucher_type = type;
      if (type === 1) {
        this.form.voucher_value = this.voucher_amount || 0;
      } else {
        this.form.voucher_value = this.voucher_percent || 0;
      }
    },
    async createVoucherGroup() {
      try {
        const response = await Vue.prototype.$rf
          .getRequest("CampaignRequest")
          .createVoucherGroup({
            name: this.form.name,
            voucher_campaign_id: parseInt(this.$route.params.id),
            voucher_value: this.form.voucher_value,
            voucher_type: this.form.voucher_type,
            max_voucher_value:
              this.form.voucher_type === 1
                ? this.form.voucher_value
                : this.form.max_voucher_value,
            workspace_id: 0,
          });
        return response.data.id; // Return the voucher group ID
      } catch (error) {
        console.error("Error creating voucher group:", error);
        this.showToastError();
        throw error; // Rethrow error to handle in handleSubmit
      }
    },

    async generateVouchers(voucherGroupId) {
      try {
        const response = await Vue.prototype.$rf
          .getRequest("CampaignRequest")
          .createVouchers({
            voucher_campaign_id: parseInt(this.$route.params.id),
            voucher_group_id: voucherGroupId,
            number_of_code: this.form.number_of_code,
            voucher_len: this.form.voucher_len,
            prefix: this.form.prefix,
          });
        console.log("Vouchers generated:", response);
      } catch (error) {
        console.error("Error generating vouchers:", error);
        this.showToastError();
        throw error; // Rethrow error to handle in handleSubmit
      }
    },

    showToastError() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: i18n.t("lbl_error_fetching_data"),
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    },

    async handleSubmit() {
      const isValid = await this.$refs.addVoucherValidation.validate();
      if (!isValid) return;

      this.loading = true;

      try {
        if (!this.form.id) {
          // Step 1: Create Voucher Group
          const voucherGroupId = await this.createVoucherGroup();

          // Step 2: Generate Vouchers
          await Promise.all([this.generateVouchers(voucherGroupId)]);
        }
        // Emit to close the modal and commit data
        this.$emit("commitData");
        this.$emit("update:is-show-voucher-modal", false);
      } catch (error) {
        // Error already handled in individual functions
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
<style lang="scss">
.rotated {
  transform: rotate(-90deg);
  /* Equal to rotateZ(45deg) */
}
.collapsible {
  overflow: hidden;
  height: 0; /* Start with height 0 */
  transition: height 100ms ease; /* Smooth height transition */
}

.collapsible.is-open {
  height: auto; /* Allow height to expand */
}

.content {
  padding: 10px; /* Padding for content */
}
</style>
