<template>
  <div>
    <div class="w-full table-product-groups">
      <div class="flex items-center justify-between mb-2">
        <h5
          class="border-left-primary pl-50 mb-0"
          style="border-left-width: 4px"
        >
          {{ "Sản phẩm áp dụng" }}
        </h5>
        <b-button
          variant="link"
          class="m-0items-center gap-2"
          style="display: flex !important"
          :disabled="!campaign.id"
          @click="openModalProduct"
          ><PlusCircleIcon size="14" />{{
            `${$t("lbl_add_new")} Sản phẩm`
          }}</b-button
        >
      </div>
      <LayoutDataTable
        v-if="products.length || searchQueryProducts"
        :isLoading="isLoading"
        :searchQuery="searchQueryProducts"
        :perPage="perPageProducts"
        :meta="dataMetaProduct"
        :currentPage="currentPageProducts"
        :totals="totalProducts"
        :searchPlaceholder="$t('lbl_search')"
        @setPerPage="
          (value) => ((perPageProducts = value), (currentPageProducts = 1))
        "
        @setCurrentPage="(value) => (currentPageProducts = value)"
        @setSearchQuery="
          (value) => ((searchQueryProducts = value), (currentPageProducts = 1))
        "
      >
        <template #default>
          <b-table
            ref="refProductsTable"
            striped
            hover
            empty-text="Không có dữ liệu"
            :items="products"
            :fields="productsFields"
          >
            <template #cell(name)="data">
              <b>{{ data.item.name || "--" }}</b>
            </template>
            <template #cell(created_at)="data">
              <span> {{ moment(data.item.created_at).format('HH:mm DD/MM/YYYY') }}</span>
            </template>
            <template #cell(updated_at)="data">
              <span> {{ moment(data.item.updated_at).format('HH:mm DD/MM/YYYY') }}</span>
            </template>
          </b-table>
        </template>
      </LayoutDataTable>
      <ProductModal
        :is-show-product-modal.sync="isShowModalProduct"
        @commitData="commitData"
      />
    </div>
  </div>
</template>

<script>
import { required } from "@validations";
import appUtils from "@/utils/appUtils";
import Vue from "vue";
import i18n from "@/libs/i18n";
import LayoutDataTable from "@/layouts/table/LayoutDataTable.vue";
import useActionsCampaigns from "../../views/Campaigns/_id/useActionsCampaigns";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { PlusCircleIcon } from "vue-feather-icons";
import ProductModal from "@/components/Campaign/ProductModal.vue";
import moment from 'moment';

export default {
  components: {
    LayoutDataTable,
    PlusCircleIcon,
    ProductModal,
  },
  props: {
    campaign: {
      type: Object,
      default: () => {},
    },
  },
  setup() {
    const mixData = useActionsCampaigns();

    return { ...mixData };
  },
  async created() {
    if (this.$route.params.id !== "add") {
      this.fetchProducts();
    }
  },
  computed: {},

  data() {
    return {
      required,
      appUtils,
      isShowModalProduct: false,
      productGroupSelected: {},
      productsFields: [
        { key: "name", label: "Tên product" },
        { key: "created_at", label: "Ngày tạo" },
        { key: "updated_at", label: "Ngày cập nhật" },
      ],
    };
  },
  watch: {
    currentPageProducts(newVal) {
      console.log(newVal);
      this.currentPageProducts = newVal;
      this.fetchProducts();
    },
    perPageProducts(newVal) {
      this.perPageProducts = newVal;
      this.fetchProducts();
    },
    searchQueryProducts(newVal) {
      this.searchQueryProducts = newVal;
      this.fetchProducts();
    },
  },

  mounted() {},
  methods: {
    moment,
    openModalProduct() {
      this.isShowModalProduct = true;
    },
    closeModalProduct() {
      console.log("closeModalProduct");
      this.productGroupSelected = {};
      this.isShowModalProduct = false;
    },

    async commitData() {
      this.fetchProducts();
    },

    async fetchProducts() {
      this.isLoadingProduct = true;
      const userData = appUtils.getLocalUser();

      if (!userData || !userData?.supplierInfo?.id) return;
      await Vue.prototype.$rf
        .getRequest("CampaignRequest")
        .getProductGroups({
          page_size: this.perPageProducts,
          page: this.currentPageProducts,
          search: this.searchQueryProducts,
          campaign_id: parseInt(this.$route.params.id),
          supplier_id: userData?.supplierInfo?.id,
        })
        .then((response) => {
          const { data, total } = response.data || [];
          this.totalProducts = total;
          this.products = data.map((e) => ({
            name: e.name,
            created_at: e.created_at,
            updated_at: e.updated_at,
          }));
        })
        .catch((e) => {
          console.log(e);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: i18n.t("lbl_error_fetching_data"),
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.isLoadingProduct = false;
        });
    },
  },
};
</script>

<style lang="scss">
.table-product-groups {
  .m-2 {
    margin: 0 0 12px 0 !important;
  }
  .card {
    width: 100%;
  }
  .pagination-customize {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
</style>
