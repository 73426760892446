<template>
  <div>
    <div class="w-full table-voucher-groups">
      <div class="flex items-center justify-between mb-2">
        <h5
          class="border-left-primary pl-50 mb-0"
          style="border-left-width: 4px"
        >
          {{ "Danh sách Nhóm Vouchers" }}
        </h5>
        <b-button
          variant="link"
          class="m-0items-center gap-2"
          style="display: flex !important"
          :disabled="!campaign.id"
          @click="
            () => {
              openModalVoucher();
              voucherGroupSelected = {};
            }
          "
          ><PlusCircleIcon size="14" />{{
            `${$t("lbl_add_new")} Nhóm Vouchers`
          }}</b-button
        >
      </div>
      <LayoutDataTable
        v-if="voucherGroups.length || searchQueryVoucherGroups"
        :isLoading="isLoading"
        :searchQuery="searchQueryVoucherGroups"
        :perPage="perPageVoucherGroups"
        :meta="dataMetaVoucherGroup"
        :currentPage="currentPageVoucherGroups"
        :totals="totalVoucherGroups"
        :searchPlaceholder="$t('lbl_search')"
        @setPerPage="
          (value) => (
            (perPageVoucherGroups = value), (currentPageVoucherGroups = 1)
          )
        "
        @setCurrentPage="(value) => (currentPageVoucherGroups = value)"
        @setSearchQuery="
          (value) => (
            (searchQueryVoucherGroups = value), (currentPageVoucherGroups = 1)
          )
        "
      >
        <template #default>
          <b-table
            ref="refVoucherGroupsTable"
            striped
            hover
            empty-text="Không có dữ liệu"
            :items="voucherGroups"
            :fields="voucherGroupsFields"
            @row-clicked="handleRowClick"
          >
            <template #cell(name)="data">
              <div class="d-flex items-center">
                <b-form-input
                  v-if="rowSelected.id === data.item.id"
                  v-model="rowSelected.name"
                  @blur="updateVoucherGroup"
                />
                <b v-else>{{ data.item.name || "--" }}</b>
                <svg
                  @click.stop="() => selectRowEdit(data.item)"
                  style="margin-left: 4px"
                  class="transition-all duration-300 hover-icon"
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="m13.26 3.6-8.21 8.69c-.31.33-.61.98-.67 1.43l-.37 3.24c-.13 1.17.71 1.97 1.87 1.77l3.22-.55c.45-.08 1.08-.41 1.39-.75l8.21-8.69c1.42-1.5 2.06-3.21-.15-5.3-2.2-2.07-3.87-1.34-5.29.16Z"
                    stroke="#757281"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    d="M11.89 5.05a6.126 6.126 0 0 0 5.45 5.15M3 22h18"
                    stroke="#757281"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
            </template>
            <template #cell(voucher_value)="data">
              <span>
                {{
                  `${appUtils.numberFormat(data.item.voucher_value)} ${
                    data.item.voucher_type === 1 ? "VNĐ" : "%"
                  } 
            `
                }}
              </span>
            </template>
            <template #cell(max_voucher_value)="data">
              <span
                >{{
                  appUtils.formatNumber(data.item.max_voucher_value)
                }}
                VNĐ</span
              >
            </template>
            <template #cell(end_date)="data">
              <span> {{ moment(data.item.end_date).format('DD/MM/YYYY') }}</span>
            </template>
          </b-table>
        </template>
      </LayoutDataTable>
      <VoucherModal
        :is-show-voucher-modal.sync="isShowModalVoucher"
        :data="voucherGroupSelected"
        @commitData="commitData"
        @close="closeModalVoucher"
      />
    </div>
  </div>
</template>

<script>
import { required } from "@validations";
import appUtils from "@/utils/appUtils";
import Vue from "vue";
import i18n from "@/libs/i18n";
import LayoutDataTable from "@/layouts/table/LayoutDataTable.vue";
import useActionsCampaigns from "../../views/Campaigns/_id/useActionsCampaigns";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { PlusCircleIcon } from "vue-feather-icons";
import VoucherModal from "@/components/Campaign/VoucherModal.vue";

export default {
  components: {
    LayoutDataTable,
    PlusCircleIcon,
    VoucherModal,
  },
  props: {
    campaign: {
      type: Object,
      default: () => {},
    }
  },
  setup() {
    const mixData = useActionsCampaigns();

    return { ...mixData };
  },
  async created() {
    if (this.$route.params.id !== "add") {
      this.fetchVoucherGroups();
    }
  },
  computed: {},

  data() {
    return {
      required,
      appUtils,
      isShowModalVoucher: false,
      voucherGroupSelected: {},
      rowSelected: {},
      voucherGroupsFields: [
        { key: "name", label: "Tên voucher" },
        { key: "voucher_value", label: "Giá trị" },
        { key: "max_voucher_value", label: "Giá trị tối đa" },
        { key: "end_date", label: "Ngày kết thúc" },
      ],
    };
  },
  watch: {
    currentPageVoucherGroups(newVal) {
      console.log(newVal);
      this.currentPageVoucherGroups = newVal;
      this.fetchVoucherGroups();
    },
    perPageVoucherGroups(newVal) {
      this.perPageVoucherGroups = newVal;
      this.fetchVoucherGroups();
    },
    searchQueryVoucherGroups(newVal) {
      this.searchQueryVoucherGroups = newVal;
      this.fetchVoucherGroups();
    },
  },

  mounted() {},
  methods: {
    openModalVoucher() {
      this.isShowModalVoucher = true;
      this.rowSelected = {};
    },
    closeModalVoucher() {
      console.log("closeModalVoucher");
      this.voucherGroupSelected = {};
      this.isShowModalVoucher = false;
    },

    selectRowEdit(item) {
      this.rowSelected = item;
      this.isShowModalVoucher = false;
      console.log(item);
    },
    async commitData() {
      this.fetchVoucherGroups();
    },
    async fetchVoucherGroups() {
      this.isLoadingVoucherGroup = true;
      await Vue.prototype.$rf
        .getRequest("CampaignRequest")
        .getVoucherGroups(this.$route.params.id, {
          page_size: this.perPageVoucherGroups,
          page: this.currentPageVoucherGroups,
          search: this.searchQueryVoucherGroups,
        })
        .then((response) => {
          const { data, total } = response.data || [];
          this.totalVoucherGroups = total;
          this.voucherGroups = data.map((e) => ({
            id: e.id,
            name: e.name,
            voucher_value: e.voucher_value,
            voucher_type: e.voucher_type,
            max_voucher_value: e.max_voucher_value,
            created_at: e.created_at,
          }));
        })
        .catch((e) => {
          console.log(e);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: i18n.t("lbl_error_fetching_data"),
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.isLoadingVoucherGroup = false;
        });
    },

    async fetchVouchers() {
      this.isLoadingVoucher = true;
      await Vue.prototype.$rf
        .getRequest("CampaignRequest")
        .getVouchers({
          page_size: this.perPageVoucherGroups,
          page: this.currentPageVoucherGroups,
          search: this.searchQueryVoucherGroups,
          campaign_id: this.$route.params.id,
        })
        .then((response) => {
          const { data, total } = response.data || [];
          this.totalVouchers = total;
          this.vouchers = data.map((e) => ({
            code: e.code,
            created_at: e.created_at,
            is_redeemed: e.is_redeemed,
          }));
        })
        .catch((e) => {
          console.log(e);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: i18n.t("lbl_error_fetching_data"),
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.isLoadingVoucher = false;
        });
    },

    async updateVoucherGroup() {
      try {
        await Vue.prototype.$rf
          .getRequest("CampaignRequest")
          .updateVoucherGroup({
            id: this.rowSelected.id,
            name: this.rowSelected.name,
          });
      } catch (error) {
        console.error("Error creating voucher group:", error);
        this.showToastError();
        throw error; // Rethrow error to handle in handleSubmit
      } finally {
        this.rowSelected = {}
      }
    },

    handleRowClick(item) {
      this.openModalVoucher();
      this.voucherGroupSelected = item;
    },
  },
};
</script>

<style lang="scss">
.table-voucher-groups {
  .m-2 {
    margin: 0 0 12px 0 !important;
  }
  .card {
    width: 100%;
  }
  .pagination-customize {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  /* Hide icon by default */
  .hover-icon {
    transition: all 0.1s ease-in-out;
    visibility: hidden;
  }

  /* Show icon when row is hovered */
  .b-table tbody tr:hover .hover-icon {
    visibility: visible;
  }
}
</style>
